<template>
<!-- =================================================================
    Componente specializzato nella costruzione automatica di un FORM
    con le domande di un questionario. Identifica il tipo di campo
    e lo posiziona nel form.
    ================================================================== //-->
    <div class="q-pa-md justify-center" v-if="is_mounted">
    <!--    <q-form
            @submit="onSubmit"
            @reset="onReset"
            class="q-gutter-md"
            > //-->
            <div v-for="(Domanda, index) in domande"
                            v-bind:key="index"
                            v-bind="Domanda"
                            class="row"
                            @input="resultDomande()">

                <div class="col-md-3"></div>
                <div class="col-12 col-md-6">
                    <QQDomanda
                        :questionario_completo="domande"
                        :domanda="Domanda"
                        :analisi_necessita="analisi_necessita"
                        @error-checked="onErrorChecked(index,$event)"
                        @on-btn-clicked="onButtonClicked"
                        :visible="isDomandaVisible(Domanda)"
                        @qq-domanda-change="$emit('qq-questionario-change', Domanda)"
                        @qq-domanda-filter="$emit('qq-questionario-filter',{
                            val: $event,
                            domanda: Domanda
                        })"
                        @qq-domanda-inputSelect-filter="$emit('qq-questionario-inputSelect-filter', {
                            ...$event,
                            domanda: Domanda
                        })"
                    />

                    <!-- @qq-domanda-filter="$emit('qq-questionario-filter',{
                            val: $event.val,
                            update: $event.update,
                            domanda: Domanda
                        })" //-->
                </div>
                <div class="col-md-3"></div>

            </div>
    <!--     </q-form> //-->
    </div>
</template>

<script>

    import QQDomanda from "@/components/QQDomanda.vue";
    import commonLib from "@/libs/commonLib";
    import { mapActions } from "vuex";

    export default {
        name: "QQQuestionario",
        data() {
            return {
                values: {},
                options: [],
                sub_value: [],
                have_errors: [],
                is_mounted: false
            }
        },
        props: {
            domande: {
                type: Array,
                required: true
            },
            analisi_necessita: {
                type: Array,
                required: false,
                default: function() {
                    return [];
                }
            }
        },
        components: {
            QQDomanda
        },
        methods: {
            ...mapActions({
                fetchElencoCompagnieDaCatalogo: "gestioneCatalogoProdotti/fetchElencoCompagnieDaCatalogo"
            }),
            onQQDomandaChange(){
                //console.log('cliccato domanda con nome: '+ domanda.name)
            },
            onButtonClicked(label) {
                this.$emit("on-btn-clicked",label);
            },
            resultDomande() {
                this.$emit('input',this.domande);

                return this.domande;
            },
            onErrorChecked(index,isNotValidForm) {
                this.have_errors[index] = isNotValidForm;
                this.$emit('error-checked',this.have_errors);
            },
            isDomandaVisible(SingolaDomanda) {
                if (!SingolaDomanda.is_visible) return false;

                if (!commonLib.isEmpty(SingolaDomanda.is_visible_if)) {
                    // Splitta tutte le condizioni separate da punto e virgola
                    let condizioni = SingolaDomanda.is_visible_if.split(";");
                    let result = true;

                    condizioni.forEach(cond => {
                        // Recupera i nome del campo di riferimento
                        let NomeCampo = cond.split(":")[0];
                        let ValoreCampo = cond.split(":")[1];

                    //    console.log("NomeCampo:",NomeCampo,ValoreCampo);

                        // Cerca il campo con l'attributo NAME uguale a quello cercato
                        let Campo = this.domande.filter(domanda => {
                            return domanda.name == NomeCampo;
                        });

                        // Se Campo è vuoto allora prova a cercare nell'analisi necessita
                        if (Campo.length === 0) {
                            Campo = this.analisi_necessita.filter(domanda => {
                                return domanda.name == NomeCampo;
                            });
                        }

                        // Cerca il valore impostato sul campo appena trovato
                        let value = ""
                        if (Campo.length > 0) {
                            value = Campo[0].value;
                        }

                        if (value == "") result = result && false;

                        result = result && ValoreCampo.includes(value);
                    });

                    return result;
                }

                return true;
                //return SingolaDomanda.is_visible;
            },
        },

        async mounted() {
            this.is_mounted = false;
            var elenco_compagnie = await this.fetchElencoCompagnieDaCatalogo(true);
            //console.log("elenco_compagnie:",elenco_compagnie);

            // Cerca se esiste una domanda con name=compagnia
            var domanda = this.domande.filter(d => {
                return d.name === "compagnia";
            });

            if (!commonLib.isEmpty(domanda)) {
                domanda[0].options = [];
                if (!commonLib.isEmpty(domanda)) {
                    domanda[0].options = elenco_compagnie;
                }
            }

            // Nel caso siano presenti domande di tipo group_checkbox, se si tratta
            // di prodotti vecchi bisogna aggiustare il cmpo VALUE come array e non
            // come stringa "[]"
            this.domande.forEach(domanda => {
                if (domanda.type === "group_checkbox") {
                    if (typeof(domanda.value) === "string") {
                        domanda.value = JSON.parse(domanda.value);
                        console.log("domanda.value:",typeof(domanda.value));
                    }

                    //if (domanda.value == "[]") domanda.value = [];
                    //console.log("domanda.value old:",typeof(domanda.value));
                }
            })

            this.is_mounted = true;
        }
    }

</script>

<style scoped>

</style>
